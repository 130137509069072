import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { SubscriptionModel } from '@app/+authenticated/+reports/shared/subscriptions/subscription.model';
import { getAccountSubscription } from '@app/reducers/account/account.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { AppState } from '../reducers/index';

@Pipe({
  name: 'subscriptionCurrency',
  pure: false, // required to update the value when the subscription is resolved
  standalone: true,
})
export class SubscriptionCurrencyPipe implements PipeTransform, OnDestroy {
  private value: string;
  private lastAmount: string | number;
  private lastPrecision: number;
  private onCurrencyChange: Subscription;

  public constructor(
    private store: Store<AppState>,
    private _ref: ChangeDetectorRef,
    private translate: TranslateService,
  ) {}

  public transform(amount: string | number, precision: number = 2): string {
    //make sure the function performs when checked multiple times
    if (amount === this.lastAmount && precision === this.lastPrecision) {
      return this.value;
    }

    this.lastAmount = amount;
    this.lastPrecision = precision;

    // if there is a subscription to onCurrencyChange, clean it
    this._dispose();

    this.onCurrencyChange = this.store
      .select(getAccountSubscription)
      .pipe(filter((subscription) => !!subscription))
      .pipe(take(1))
      .subscribe((subscription: SubscriptionModel) => {
        this.value = this.updateValue(subscription.currency_code, amount, precision);
        this._ref.markForCheck();
      });

    return this.value;
  }

  public updateValue(currencyCode: string, amount: string | number, precision: number) {
    if (typeof amount === 'string') {
      amount = parseFloat(amount);
    }

    const options: Intl.NumberFormatOptions = {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    };

    if (currencyCode) {
      options.style = 'currency';
      options.currency = currencyCode;
    }

    const numberFormat = new Intl.NumberFormat(this.translate.currentLang, options);
    return numberFormat.format(Number.isNaN(amount) ? 0 : amount);
  }

  /**
   * Clean any existing subscription to change events
   * @private
   */
  public _dispose(): void {
    if (typeof this.onCurrencyChange !== 'undefined') {
      this.onCurrencyChange.unsubscribe();
      this.onCurrencyChange = undefined;
    }
  }

  public ngOnDestroy(): void {
    this._dispose();
  }
}
